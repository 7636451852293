<template>
  <div class="detail tw-flex">
    <div class="detail_left tw-flex-1">
      <div class="panel chain">
        <div class="panel_title">
          <p class="tw-text-16px tw-font-bold">服务链详情</p>
        </div>
        <div class="chain_content">
          <div
            class="service"
            v-for="(item, index) in steps"
            :key="index">
            <div class="service_type">
              <p class="service_type_name tw-text-26px tw-font-bold tw-flex tw-items-center">
                {{item.serviceId ? item.serviceName : item.serviceTypeName}}
              </p>
              <p class="service_type_desc tw-text-14px">
                {{item.serviceId ? item.service.description : '未选择具体服务'}}
              </p>
            </div>
            <div class="service_divider"></div>
            <div class="service_org org">
              <template v-if="item.serviceId">
                <div class="service_org_header tw-flex tw-justify-between tw-items-center">
                  <div class="tw-flex tw-items-center">
                    <img
                      class="org_img"
                      :src="item.userDetails.avatarUrl || '../../../assets/images/default_avatar.png'"
                      style="object-fit: cover;"
                      alt="" />
                    <p class="org_name tw-text-16px">{{item.userDetails.organizationName}}</p>
                  </div>
                  <p class="org_price tw-text-20px tw-font-bold">
                    {{item.service.fee === '面议' ? '面议' : item.service.fee + '元'}}
                  </p>
                </div>
                <div class="service_org_main">
                  <div class="org_desc tw-text-12px">
                    <p>产业特长：{{getTechnicalNames(item.userDetails.organization.industryExpertise)}}</p>
                    <p>联系人：{{item.userDetails.username}}</p>
                    <p>联系电话：{{item.userDetails.mobile}}</p>
                  </div>
                  <div class="org_data">
                    <div class="org_data_item">
                      <p
                        class="tw-text-20px tw-font-bold"
                        style="color: #3473E6;">
                        {{item.userDetails.organization.avgScore}}
                      </p>
                      <p class="tw-text-12px">信用评价</p>
                    </div>
                    <!-- <div class="org_data_item">
                      <p class="tw-text-20px tw-font-bold" style="color: #F1B221;">{{item.orgInfo.serviceFrequency}}</p>
                      <p class="tw-text-12px">服务次数</p>
                    </div> -->
                  </div>
                </div>
              </template>
            </div>
            <div class="service_btn">
              <el-button
                type="text"
                style="padding:0;"
                @click="onChange(index)">
                更换服务
              </el-button>
              <el-button
                type="text"
                style="margin:20px 0 0 0;padding:0;"
                @click="onDelete(index)">
                删除服务
              </el-button>
            </div>
          </div>
        </div>
        <div class="chain_order">
          <el-button type="primary" @click="placeOrder">
            确认下单
            <i class="iconfont icongouwu" style="margin-left:10px;"></i>
          </el-button>
        </div>
      </div>
    </div>
    <div class="detail_right">
      <div class="panel" style="padding-bottom:22px;">
        <div class="panel_title">
          <p class="tw-text-16px tw-font-bold">服务链名称</p>
        </div>
        <p class="chain_name tw-text-20px tw-font-bold">{{serviceChain.name}}</p>
        <div class="panel_title">
          <p class="tw-text-16px tw-font-bold">服务链简介</p>
        </div>
        <p class="chain_desc tw-text-14px">{{serviceChain.description}}</p>
      </div>
    </div>

    <el-dialog
      :visible="dialogVisible"
      title="更换服务"
      :show-close="false"
      @open="onSearch">
      <div class="dialog_body">
        <div class="dialog_body_input">
          <el-input
            v-model="keywords"
            placeholder="请输入服务名称搜索"
            prefix-icon="el-icon-search"
            @keyup.native.enter="onSearch">
          </el-input>
        </div>
        <el-checkbox-group
          v-model="checkedService"
          :max="1">
          <el-table
            :data="tableData"
            highlight-current-row
            :header-cell-style="headerCellStyle"
            height="400"
            v-loading="tableLoading">
            <el-table-column
              width="50"
              align="center">
              <template slot-scope="scope">
                <el-checkbox :label="scope.row.id">
                  <span></span>
                </el-checkbox>
              </template>
            </el-table-column>
            <el-table-column
              label="服务名称"
              prop="title"
              align="center">
            </el-table-column>
            <el-table-column
              label="机构名称"
              prop="orgInfo.orgName"
              align="center">
              <template slot-scope="scope">
                <div class="dialog_body_org">
                  <img
                    class="dialog_body_org-img"
                    :src="scope.row.userDetails.avatarUrl || '../../../assets/images/default_avatar.png'"
                    style="object-fit: cover;"
                  />
                  <p class="dialog_body_org-name tw-text-14px tw-font-bold">
                    {{scope.row.userDetails.organizationName}}
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="信用分"
              prop="credit"
              align="center">
              <template slot-scope="scope">
                <span class="tw-text-16px tw-font-bold" style="color:#3473E6;">
                  {{scope.row.userDetails.organization.avgScore}}
                </span>
              </template>
            </el-table-column>
            <!-- <el-table-column
              label="交易量"
              prop="count"
              align="center">
              <template slot-scope="scope">
                <span class="tw-text-16px tw-font-bold" style="color:#F1B221;">
                  {{scope.row.orgInfo.serviceFrequency}}
                </span>
              </template>
            </el-table-column> -->
          </el-table>
        </el-checkbox-group>
        <div class="dialog_body_pagination tw-flex tw-justify-center tw-items-center">
          <el-pagination
            hide-on-single-page
            background
            @current-change="pageNumChange"
            :current-page.sync="pagination.pageNum"
            :page-size="pagination.pageSize"
            layout="prev, pager, next, jumper"
            :total="pagination.total">
          </el-pagination>
        </div>
      </div>
      <div slot="footer">
        <el-button
          size="small"
          @click="dialogVisible = false">
          取消
        </el-button>
        <el-button
          type="primary"
          size="small"
          @click="onConfirm">
          更换服务
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as api from '@/api'

export default {
  data () {
    return {
      tableLoading: false,
      serviceChain: {},
      steps: [],
      dialogVisible: false,
      editIndex: -1,
      keywords: '',
      checkedService: [],
      tableData: [],
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      } // 分页对象
    }
  },
  computed: {
    headerCellStyle () {
      return {
        backgroundColor: '#F7F8F9',
        color: '#909399',
        fontWeight: 'bold'
      }
    },
    id () {
      return this.$route.params.id
    },
    // 技术领域
    technicalList () {
      return this.$store.state.technicalList
    },
    getTechnicalNames () {
      return (technical) => {
        const ids = technical ? technical.split(',') : []
        let result = ''

        this.technicalList.forEach(item => {
          if (ids.includes(item.id)) {
            result += result === '' ? item.name : ',' + item.name
          }
        })
        return result
      }
    }
  },
  created () {
    this.getServiceChainDetail(this.id)
  },
  methods: {
    getServiceChainDetail (id) {
      api.getServiceChainDetail(id).then(res => {
        if (res.data.code === 0) {
          this.serviceChain = res.data.data
          this.steps = []
          this.serviceChain.steps.forEach(element => {
            this.steps.push({
              ...element
            })
          })
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        this.$message.error('请求出错')
        console.log(err)
      })
    },
    onChange (index) {
      this.editIndex = index
      this.checkedService = []
      this.dialogVisible = true
    },
    onSearch () {
      this.getServiceList(1)
    },
    getServiceList (pageNum) {
      this.tableLoading = true
      api.getServiceListByType({
        pageNum,
        pageSize: this.pagination.pageSize,
        keywords: this.keywords,
        serviceTypeId: this.steps[this.editIndex].serviceTypeId
      }).then(res => {
        if (res.data.code === 0) {
          this.pagination.pageNum = res.data.data.currentPage
          this.pagination.total = res.data.data.total
          this.tableData = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.tableLoading = false
      })
    },
    onDelete (index) {
      this.steps.splice(index, 1)
    },
    pageNumChange (page) {
      this.checkedService = []
      this.getServiceList(page)
    },
    onConfirm () {
      if (this.checkedService.length <= 0) return
      let step = {}
      this.tableData.forEach(element => {
        if (element.id === this.checkedService[0]) {
          step = {
            serviceId: element.id,
            serviceName: element.title,
            serviceTypeId: element.serviceTypeId,
            serviceTypeName: element.serviceTypeName,
            service: { ...element },
            userDetails: { ...element.userDetails }
          }
        }
      })
      if (step.serviceId) {
        this.steps.splice(this.editIndex, 1, step)
        this.dialogVisible = false
      }
    },
    placeOrder () {
      const steps = []
      let isValid = true
      this.steps.forEach(element => {
        if (!element.serviceId || !element.serviceTypeId) {
          isValid = false
        }
        steps.push({
          serviceId: element.serviceId,
          serviceTypeId: element.serviceTypeId
        })
      })
      if (steps.length <= 0 || !isValid) {
        this.$message.warning('请完善服务选择')
        return
      }
      this.$confirm('下单后会有工作人员与您联系', '确认下单', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.createServiceChainOrder({
          steps,
          serviceChainId: this.serviceChain.id
        }).then(res => {
          if (res.data.code === 0) {
            this.$message.success('下单成功')
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          this.$message.error('请求出错')
          console.log(err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  min-width: 1350px;
  &_left {
    margin-right: 20px;
  }
  &_right {
    width: 450px;
  }
}

.panel {
  padding: 0 30px;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  &_title {
    padding: 20px 3px;
    & > p {
      position: relative;
      color: #000000;
      line-height: 22px;
      &::before {
        content: "";
        position: absolute;
        left: -13px;
        top: 50%;
        transform: translateY(-50%);
        width: 3px;
        height: 20px;
        background-color: #3473E6;
        border-radius: 1px;
      }
    }
  }
}

.chain {
  &_content {
    margin: 0 -10px;
  }
  &_order {
    padding: 20px 0 30px 0;
  }
  &_name {
    margin-bottom: 30px;
    line-height: 28px;
    color: #3473E6;
  }
  &_desc {
    line-height: 30px;
    color: #909399;
  }
}

.service {
  min-height: 200px;
  margin: 10px 0;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  &_type {
    width: 370px;
    padding: 20px 0px 20px 50px;
    &_name {
      height: 60px;
      color: #303133;
    }
    &_desc {
      margin-top: 10px;
      color: #909399;
      line-height: 20px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
  }
  &_divider {
    margin: 0 30px 0 40px;
    width: 1px;
    height: 80px;
    border-left: 1px solid #DCDFE6;
  }
  &_org {
    flex: 1;
    min-width: 400px;
    height: 180px;
    background: rgba(255, 255, 255, 0);
    border: 1px solid rgba(52, 115, 230, 0.10196078431372549);
    opacity: 1;
    border-radius: 4px;
    &_header {
      height: 66px;
      padding: 0 20px;
      background-image: url('../../../assets/images/service_org_bg.png');
      background-repeat: no-repeat;
      background-position: right;
    }
    &_main {
      margin-top: 10px;
      padding: 0 30px 0 30px;
      display: flex;
    }
  }
  .org {
    &_img {
      width: 40px;
      height: 40px;
    }
    &_name {
      margin-left: 10px;
      line-height: 22px;
    }
    &_price {
      color: #FF4343;
    }
    &_desc {
      flex: 1;
      height: 80px;
      margin-right: 25px;
      color: #909399;
      line-height: 20px;
    }
    &_data {
      display: flex;
      padding: 20px 0 10px 0;
      &_item {
        width: 100px;
        text-align: center;
        & > p:first-child {
          line-height: 30px;
        }
        & > p:last-child {
          line-height: 20px;
          color: #303133;
        }
      }
    }
  }
  &_btn {
    height: 100%;
    padding: 70px 35px 70px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.dialog_body {
  padding: 0 20px;
  &_input {
    margin-bottom: 20px;
  }
  &_org {
    display: flex;
    align-items: center;
    &-img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 10px;
    }
    &-name {
      line-height: 20px;
      color: #303133;
    }
  }
  &_pagination {
    margin-top: 20px;
    height: 40px;
  }
}
</style>
